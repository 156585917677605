<template>
  <b10-list-item
    :item="formattedItem"
  >
    <v-list-item-avatar>
      <div
        class="text-xs-center"
      >
        <v-avatar
          :color="formattedItem.avatarColor"
        >
          <span class="white--text font-weight-bold subtitle-1">{{ formattedItem.avatar }}</span>
        </v-avatar>
      </div>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title
        v-html="formattedItem.title"
      />
      <v-list-item-subtitle
        v-html="formattedItem.subtitle"
      />
    </v-list-item-content>
  </b10-list-item>
</template>

<script>
import _ from '@/utils/lodash'
import { stringToHslColor } from '@/utils/ui'
import { calculateMd5 } from '@/utils/crypto'
import { nonEmpty } from '@/utils/templates'

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    formattedItem () {
      const item = _.cloneDeep(this.item)
      item.title = this.$options.filters.linebreaksBr(nonEmpty`${item.subsis.descripcion} (${item.subsis.codigo})`)
      item.subtitle = item.tsubsis.descripcion
      item.avatar = this.$options.filters.initials(item.tsubsis.descripcion)
      item.avatarColor = stringToHslColor(calculateMd5(item.tsubsis.descripcion))
      return item
    }
  },
}
</script>
